import '../styles/ResearchPage.css';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../authentication/AuthContext';
import trashBin from '../components/icons/trash_bin.png';
import { Box, Typography, Divider, Tabs, Tab } from '@mui/material';

import Step1Survey from './Step1Survey';
import Step2Personas from './Step2Personas';
import Step3Responses from './Step3Responses';
import Step4FinalReview from './Step4FinalReview';
import { api } from '../services/api';
import { mapServerResponsesToLocal, mapServerQuestionsToLocal } from '../services/apiUtils'; // <-- import

function ResearchPage() {
  const { user, sub } = useAuth();
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  // Research workflow state from App.js
  const [userData, setUserData] = useState({
    userID: sub,
    researchID: '',
    description: '', // The one provided by the user, not equal to surveyDescription (generated by llm)
    surveyDescription: '',
    surveyQuestions: null,
    selectedPersonas: [],
    personaFilters: null,
    surveyResponses: null,
    progress: 1 // 1=Survey, 2=Personas, 3=Responses, 4=FinalReview
  });


  useEffect(() => {
    if (sub) {
      Promise.all([
        api.getPreviousResearches(sub),
        api.getPreviousResponseData(sub),
        api.getAllPreviousPersonas(sub),
      ])
      .then(([loadedTopics, responsesList, personasDict]) => {
        // Merge each topic with its responses from the map
        const mergedTopics = loadedTopics.map(topic => {
          const match = responsesList.find(item => item.researchID === topic.researchID);
          const surveyResponses = mapServerResponsesToLocal(match?.responses || null, match?.researchID || null);

          const selectedPersonas = personasDict[topic.researchID] || [];
          
          return { ...topic, surveyResponses: surveyResponses, selectedPersonas: selectedPersonas};
        });

        setTopics(mergedTopics);

        console.log("all topics data loaded for this user: ",mergedTopics);

      })
      .catch(error => {
        console.error('Error loading research data or responses:', error);
      });
      
    }
  }, [sub]);

    // Handle tab change
    const handleTabChange = (event, newValue) => {
      setTabIndex(newValue);

    };

  // Topic handlers
  const handleNewResearchClick = () => {
    setSelectedTopic({ 
      userID: sub,
      researchID: '',
      description: '',
      surveyDescription: '',
      surveyQuestions: null,
      selectedPersonas: [],
      personas: [],
      surveyResponses: null,
      progress: 1 // 1=Survey, 2=Personas, 3=Responses, 4=FinalReview
    });
    // setIsSidebarVisible(false);
    setTabIndex(0);

    setUserData({
      userID: sub,
      researchID: '',
      description: '',
      surveyDescription: '',
      surveyQuestions: null,
      selectedPersonas: [],
      personaFilters: null,
      surveyResponses: null,
      progress: 1
    });
  };
  const handleTopicSelect = async (topic) => {
    const surveyQuestions = mapServerQuestionsToLocal(topic.survey?.questions || null) || [];
    const selectedPersonas = topic.selectedPersonas || [];
    const surveyResponses = topic.surveyResponses || [];
    
    let progress = 1; // Start with Survey
    
    if (surveyQuestions.length > 0) {
      progress = 2; // Survey exists, can proceed to Personas
      
      if (selectedPersonas.length > 0) {
        progress = 3; // Personas exist, can proceed to Responses
        
        if (surveyResponses.length > 0) {
          progress = 4; // Responses exist, can proceed to Final Review
        }
      }
    }

    console.log("Defining progress... ")
    console.log("num of questions is: ", surveyQuestions.length)
    console.log("num of personas is: ", selectedPersonas.length)
    console.log("num of responses is: ", surveyResponses.length)
    console.log("so progress is:",progress)
    
    console.log('Selected Topic:', topic);
    setSelectedTopic(topic);
    setUserData(prev => ({
      ...prev,
      userID: sub,
      researchID: topic.researchID,
      description: topic.description || '',
      surveyQuestions,
      surveyDescription: topic.survey?.description || '',
      selectedPersonas,
      personaFilters: null,
      surveyResponses,
      progress
    }));
  
    setTabIndex(0); // Always start by showing the Survey tab when selecting a topic
  };
  

  const handleTopicDelete = async (researchID) => {
    try {
      await api.deleteResearch(sub, researchID);
  
      const updatedTopics = topics.filter(t => t.researchID !== researchID);
      setTopics(updatedTopics);
  
      if (selectedTopic?.researchID === researchID) {
        setSelectedTopic(null);
      }
    } catch (error) {
      console.error('Error deleting research:', error);
    }
  };

  const TopicList = ({ topics, onTopicClick, onTopicDelete }) => (
    <div className="topic-list">
      {topics.length === 0 ? (
        <div className="textbox container">Loading topics...</div>
      ) : (
        <ul>
          {topics.map((topic) => (
            <li 
              key={topic.researchID} 
              onClick={() => onTopicClick(topic)}
              className={topic.isPremium ? 'premium-topic' : ''}
            >
              <span>{topic.description}</span>
              <div className="topic-actions">
                {topic.isPremium && <span className="premium-star">★</span>}
                <img 
                  src={trashBin} 
                  alt="Delete" 
                  onClick={(e) => {
                    e.stopPropagation();
                    onTopicDelete(topic.researchID);
                  }} 
                  className="click-icon" 
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="research-page">
      <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        <div className="sidebar-header">
          <h2>Your Research Topics</h2>
        </div>
        <button 
          className="button standout-button research-button" 
          style={{ maxWidth: 'none', width: '90%' }} 
          onClick={handleNewResearchClick}
        >
          New Research
        </button>
        <TopicList
          topics={topics}
          onTopicClick={handleTopicSelect}
          onTopicDelete={handleTopicDelete}
        />
      </aside>

      <button 
        className="toggle-sidebar"
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      >
        {isSidebarVisible ? '←' : '→'}
      </button>

      <main className={`research-content ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        {selectedTopic ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {selectedTopic.description}
            </Typography>
            {/* Tabs directly in ResearchPage */}
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
              >
                <Tab label="Survey" />
                <Tab label="Personas" disabled={userData.progress < 2} />
                <Tab label="Responses" disabled={userData.progress < 3} />
                <Tab label="Final Review" disabled={userData.progress < 4} />
              </Tabs>

              {/* Tab content */}
              {tabIndex === 0 && (
                <Step1Survey
                  userData={userData}
                  setUserData={setUserData}
                />
              )}
              {tabIndex === 1 && (
                <Step2Personas
                  userData={userData}
                  setUserData={setUserData}
                />
              )}
              {tabIndex === 2 && (
                <Step3Responses
                  userData={userData}
                  setUserData={setUserData}
                />
              )}
              {tabIndex === 3 && (
                <Step4FinalReview
                  userData={userData}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">
              Select a research topic or create a new one to begin
            </Typography>
          </Box>
        )}
      </main>
    </div>
  );
}

export default ResearchPage;
