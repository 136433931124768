import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/BlogPage.css'; 

const BlogCard = ({ blog }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blogs/${blog.id}#top`);
  };
  

  return (
    <div className="blog-card" onClick={handleClick} role="button" tabIndex={0}>
      <div className="blog-card-image-container">
        <img src={blog.image} alt={blog.title} className="blog-card-image" />
      </div>
      <div className="blog-card-footer">
        <span className="blog-card-category">{blog.category}</span>
        <h3 className="blog-card-title">{blog.title}</h3>
        <span className="blog-card-date">{blog.date}</span>
      </div>
    </div>
  );
};

export default BlogCard;
