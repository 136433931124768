import React, { useState } from 'react';
import { useAuth } from '../authentication/AuthContext';
import { Link } from 'react-router-dom';
import '../index.css';

function MyAccountPage() {
  const { user, credits, subscription, subscriptionCancelAt, firstName, email, changePassword } = useAuth();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    
    try {
      await changePassword(oldPassword, newPassword);
      setMessage('Password changed successfully!');
      setShowChangePassword(false);
      setOldPassword('');
      setNewPassword('');
    } catch (err) {
      setError(err.message || 'Failed to change password');
    }
  };

  const renderChangePassword = () => (
    <div className="container">
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword}>
        <div className="form-group">
          <label htmlFor="oldPassword">Current Password:</label>
          <input 
            type="password" 
            id="oldPassword" 
            value={oldPassword} 
            onChange={(e) => setOldPassword(e.target.value)} 
            required 
          />
          <label htmlFor="newPassword">New Password:</label>
          <input 
            type="password" 
            id="newPassword" 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="button standout-button">
          Update Password
        </button>
        <button 
          type="button" 
          onClick={() => setShowChangePassword(false)} 
          className="button secondary-button"
        >
          Cancel
        </button>
      </form>
    </div>
  );

  return (
    <div className="login-page">
      <div className="hero hero-1 top-hero">
        {error && <div className="error-message">{error}</div>}
        {message && <div className="success-message">{message}</div>}
        
        <div className="container">
          <h2>Account Details</h2>
          <p><strong>Name:</strong> {firstName || 'Not set'}</p>
          <p><strong>Email:</strong> {email || 'Not set'}</p>
          <button 
            className="button"
            onClick={() => setShowChangePassword(!showChangePassword)}
          >
            Change Password
          </button>
        </div>

        {showChangePassword && renderChangePassword()}

        <div className="container">
          <h2>Subscription</h2>
          <p><strong>Current Plan:</strong> {subscription || 'FREE'}</p>
          {subscriptionCancelAt && (
            <div className="alert alert-warning">
              <p>Your subscription will change to the free version on {new Date(subscriptionCancelAt).toLocaleDateString()}</p>
            </div>
          )}
          <Link to="/payment" state={{ type: 'subscription' }} className="button">
            Change Plan
          </Link>
        </div>

        <div className="container">
          <h2>Credits</h2>
          <p><strong>Available Credits:</strong> {credits || 0}</p>
          <Link to="/payment" state={{ type: 'credits' }} className="button">
            Top Up Credits
          </Link>
        </div>

      </div>
    </div>
  );
}

export default MyAccountPage;