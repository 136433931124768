import React from 'react';
import { blogData } from '../data/blogData';
import BlogCard from '../components/BlogCard';
import '../styles/BlogPage.css'; 

const sortedBlogData = blogData.sort((a, b) => new Date(b.date) - new Date(a.date));

const BlogPage = () => {
  return (
    <div className="blog-list-page">

      <header className="blog-hero">
        <h1 className="blog-hero-title">Our Blogs</h1>
        <p className="blog-hero-subtitle">
         Explore the latest insights and trends.
        </p>
      </header>

      <div className="blog-grid">
        {sortedBlogData.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogPage;