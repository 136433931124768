import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import BlogDetailPage from './pages/BlogDetailPage';
import ResearchPage from './research/ResearchPage';
import AboutPage from './pages/AboutPage';
import LoginPage from './authentication/LoginPage';
import Pricing from './pages/PricingPage';
import OurPersonas from './pages/PersonasPage';
import PaymentPage from './payment/PaymentPage';
import PaymentSuccess from './payment/PaymentSuccess';
import PaymentCancelled from './payment/PaymentCancelled';
import ResearchRequestPage from './pages/ResearchRequestPage';

import AccountPage from './pages/AccountPage';
import CareersPage from './pages/CareersPage';
import { AuthProvider } from './authentication/AuthContext';
import PrivateRoute from './authentication/PrivateRoute';
import './i18n';
import { useTranslation } from 'react-i18next';


function App() {
  
  const { t, i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage(navigator.language);
    }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/ourpersonas" element={<OurPersonas />} />
              <Route path="/blogs" element={<BlogPage />} />
              <Route path="/blogs/:blogId" element={<BlogDetailPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/payment-cancelled" element={<PaymentCancelled />} />
    
              <Route path="/careers" element={<CareersPage />} />
              <Route path="/researchrequest" element={<ResearchRequestPage />} />
              <Route path="/research" element={<PrivateRoute> <ResearchPage /> </PrivateRoute>} />
              <Route path="/payment" element={<PrivateRoute> <PaymentPage /></PrivateRoute>}/>
              <Route path="/account" element={<PrivateRoute> <AccountPage /> </PrivateRoute> }/>
            </Routes>
          </main>
          <ConditionalFooter />
        </div>
      </Router>
    </AuthProvider>
  );
}

function ConditionalFooter() {
  const location = useLocation();
  
  if (location.pathname === '/research') {
    return null;
  }
  
  return <Footer />;
}

export default App;