import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper,
  FormControlLabel,
  Checkbox,
  IconButton,
  Grid,
  ButtonGroup,
  InputAdornment
} from '@mui/material';
import { api } from '../services/api';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { mapServerQuestionsToLocal } from '../services/apiUtils';

function Step1Survey({ userData, setUserData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState("");
  const [editedSurvey, setEditedSurvey] = useState(userData.surveyQuestions || []);

  // Add this effect to update editedSurvey when userData changes (like when switching topics)
  useEffect(() => {
    setEditedSurvey(userData.surveyQuestions || []);
  }, [userData.surveyQuestions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {

        const createResult = await api.createSurvey(userData.userID, userData.description);
        console.log("createResult:", createResult);

        const formattedQuestions = mapServerQuestionsToLocal(createResult.questions);
  
        setEditedSurvey(formattedQuestions);
      
      // Update userData with survey info and progress to step 2
      setUserData(prev => ({
        ...prev,
        userID: userData.userID,
        researchID: createResult.researchID,
        surveyQuestions: formattedQuestions,
        surveyDescription: createResult.description,
        progress: Math.max(prev.progress || 0, 2) // Only update if 2 is greater than current progress
      }));
  
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

    /**
   * Before calling the update API, validate:
   * 1) Every question has non-empty text.
   * 2) Every multiple-choice question has >=2 options.
   */
  const handleContinue = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Validate that all multiple-choice questions have at least two options
      for (let i = 0; i < editedSurvey.length; i++) {
        const question = editedSurvey[i];

        // 1) Check question text
        if (!question.questionText || !question.questionText.trim()) {
          setError(
            `Question #${i + 1} has no text. Please enter some text for this question.`
          );
          setLoading(false);
          return;
        }

        // 2) Check if question is multiple-choice with fewer than 2 options
        if (
          question.questionType === 'multiple-choice' &&
          (!question.responseOptions || question.responseOptions.length < 2)
        ) {
          setError(
            `Question #${i + 1} is multiple choice but has fewer than 2 options. Please fix before continuing.`
          );
          setLoading(false);
          return;
        }
      }

      // If validation passes, update the survey
      const result = await api.updateSurvey(
        userData.userID,
        userData.researchID, 
        editedSurvey
      );


      const formattedQuestions = mapServerQuestionsToLocal(result.questions);

      console.log("the current userData is", userData)


      // Only show success and update parent component after successful update
      setEditedSurvey(formattedQuestions);
      // Update userData with the updated survey and progress
      setUserData(prev => ({
        ...prev,
        userID: userData.userID,
        researchID: userData.researchID,
        surveyQuestions: formattedQuestions,
        progress: Math.max(prev.progress || 0, 2)
      }));
      
      // Show the survey editor after successful update
    } catch (err) {
      setError('Failed to update survey: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const addQuestion = () => {
    setEditedSurvey([...editedSurvey,   { 
      questionText: '', 
      questionType: 'open', 
      responseOptions: [], 
      maxLength: 200,
      hasFixedPercentages: false,
      responsePercentages: [],
      selectMax: '',
      selectMin: ''
    }]);
  };



  const removeQuestion = (index) => {
    const updatedSurvey = [...editedSurvey];
    updatedSurvey.splice(index, 1);
    setEditedSurvey(updatedSurvey);
  };

  const removeOption = (questionIndex, optionIndex) => {
    setWarning(""); // Clear any prior warning
    const updatedSurvey = [...editedSurvey];
    const question = updatedSurvey[questionIndex];

    question.responseOptions.splice(optionIndex, 1);

    // If it's multiple-choice but now has <2 options, show a warning
    if (
      question.questionType === 'multiple-choice' &&
      question.responseOptions.length < 2
    ) {
      setWarning(
        `Question #${questionIndex + 1} now has only ${question.responseOptions.length} option(s). You must have at least 2 before continuing.`
      );
    }

    // updatedSurvey[questionIndex].responseOptions.splice(optionIndex, 1);
    setEditedSurvey(updatedSurvey);
  };

  const addOption = (questionIndex) => {
    setWarning(""); // Clear any prior warning
    const updatedSurvey = [...editedSurvey];
    updatedSurvey[questionIndex].responseOptions.push('');
    setEditedSurvey(updatedSurvey);
  };

  const toggleQuestionType = (questionIndex) => {
    setWarning("");

    const updatedSurvey = [...editedSurvey];
    const currentQuestion = updatedSurvey[questionIndex];
    
    // Store current options before changing type
    const previousOptions = currentQuestion.responseOptions || [];
    
    // Toggle the type
    currentQuestion.questionType = 
      currentQuestion.questionType === 'open' ? 'multiple-choice' : 'open';
    
    // Preserve the options even when switching to 'open'
    currentQuestion.responseOptions = previousOptions;

    setEditedSurvey(updatedSurvey);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Step 1: Create Survey
      </Typography>
      {/*
        If we have NOT generated questions yet (i.e., userData.surveyQuestions is empty),
        show the "Survey Description" form. Once questions are generated, hide it.
      */}
      {!editedSurvey?.length && (
        <Paper sx={{ p: 3, mt: 2 }}>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Survey Description"
              multiline
              rows={4}
              value={userData.description}
              onChange={(e) => {
                setUserData(prev => ({ ...prev, description: e.target.value }));

                // setFormData(prev => ({ ...prev, surveyDescription: e.target.value }));
                setError(null); // Clear the error when the form data changes
              }}
              margin="normal"
              required
            />
          {/* )} */}

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              Error: {error}
            </Typography>
          )}

          {/* {!usePrevious && ( */}
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Generate Survey'}
            </Button>
          {/* )} */}
        </Box>
      </Paper>
      )}

      {editedSurvey && editedSurvey.length > 0 && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Edit Survey
          </Typography>

          {/* Show a non-blocking “warning” for multiple-choice questions that have under 2 options. */}
          {warning && (
            <Typography sx={{ color: 'orange', mt: 1 }}>
              {warning}
            </Typography>
          )}

          <Grid container spacing={2}>
            {editedSurvey.map((question, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  fullWidth
                  label={`Question ${index + 1}`}
                  value={question.questionText}
                  onChange={(e) => {
                    const updatedSurvey = [...editedSurvey];
                    updatedSurvey[index].questionText = e.target.value;
                    setEditedSurvey(updatedSurvey);
                  }}
                  margin="normal"
                />
                <ButtonGroup 
                  variant="contained" 
                  sx={{ mt: 1, mb: 2 }}
                  aria-label="question type toggle"
                >
                  <Button
                    onClick={() => toggleQuestionType(index)}
                    sx={{
                      bgcolor: question.questionType === 'open' ? 'primary.main' : 'grey.400',
                      '&:hover': {
                        bgcolor: question.questionType === 'open' ? 'primary.dark' : 'grey.500',
                      }
                    }}
                  >
                    Open-Ended
                  </Button>
                  <Button
                    onClick={() => toggleQuestionType(index)}
                    sx={{
                      bgcolor: question.questionType === 'multiple-choice' ? 'primary.main' : 'grey.400',
                      '&:hover': {
                        bgcolor: question.questionType === 'multiple-choice' ? 'primary.dark' : 'grey.500',
                      }
                    }}
                  >
                    Multiple Choice
                  </Button>
                </ButtonGroup>

                <IconButton onClick={() => removeQuestion(index)}>
                  <RemoveIcon /> Remove Question
                </IconButton>
                
                {/* Show options if type is multiple_choice OR if there are stored options */}
                {(question.questionType === 'multiple-choice' || question.responseOptions?.length > 0) && (
                  <Box sx={{ pl: 2 }}>
                    <TextField
                      label="Select Max"
                      type="number"
                      value={question.selectMax || ""}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].selectMax = e.target.value;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      margin="normal"
                      disabled={question.questionType === 'open'}
                    />
                    <TextField
                      label="Select Min"
                      type="number"
                      value={question.selectMin || ""}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].selectMin = e.target.value;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      margin="normal"
                      disabled={question.questionType === 'open'}
                    />
                    {question.responseOptions.map((option, i) => (
                      <Box key={i} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label={`Option ${i + 1}`}
                          value={option}
                          onChange={(e) => {
                            const updatedSurvey = [...editedSurvey];
                            updatedSurvey[index].responseOptions[i] = e.target.value;
                            setEditedSurvey(updatedSurvey);
                          }}
                          margin="normal"
                          disabled={question.questionType === 'open'}
                        />
                        <IconButton 
                          onClick={() => removeOption(index, i)}
                          disabled={question.questionType === 'open'}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <IconButton 
                      onClick={() => addOption(index)}
                      disabled={question.questionType === 'open'}
                    >
                      <AddIcon />
                      Add Option
                    </IconButton>
                  </Box>
                )}
                {question.questionType === 'open' && (
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Max Length"
                      type="number"
                      value={question.maxLength || ""}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].maxLength = e.target.value;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      margin="normal"
                    />
                  </Box>
                )}
                {/* Add after line 311 in Step1Survey.js */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={question.hasFixedPercentages}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].hasFixedPercentages = e.target.checked;
                        if (e.target.checked && !updatedSurvey[index].responsePercentages) {
                          updatedSurvey[index].responsePercentages = question.responseOptions.map(() => 0);
                        }
                        setEditedSurvey(updatedSurvey);
                      }}
                    />
                  }
                  label="Fix Response Percentages"
                />

                {question.hasFixedPercentages && question.responseOptions.map((option, i) => (
                  <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>{option}:</Typography>
                    <TextField
                      type="number"
                      value={question.responsePercentages?.[i] || 0}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        const percentages = [...(updatedSurvey[index].responsePercentages || [])];
                        percentages[i] = Number(e.target.value);
                        updatedSurvey[index].responsePercentages = percentages;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      error={question.responsePercentages?.reduce((a, b) => a + b, 0) !== 100}
                      helperText={question.responsePercentages?.reduce((a, b) => a + b, 0) !== 100 ? 
                        "Percentages must sum to 100" : ""}
                    />
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
          <IconButton onClick={addQuestion}>
            <AddIcon />
            Add Question
          </IconButton>
          <Button
            variant="contained"
            onClick={handleContinue}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Paper>
      )}
    </Box>
  );
}

export default Step1Survey;