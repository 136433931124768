import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { api } from '../services/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { handleReportGeneration } from './ReportContent';
import ReportContent from './ReportContent';
import { mapServerResponsesToLocal } from '../services/apiUtils'; // <-- import


function Step3Responses({ userData, setUserData }) {
  const [loading, setLoading] = useState(false);
  const [regenerateLoading, setRegenerateLoading] = useState(null);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('byQuestion'); // 'byQuestion' or 'byPersona'

  
  const handleGenerateResponses = async () => {
    setLoading(true);
    setError(null);

    try {
      const result = await api.generateResponses(
        userData.userID,
        userData.researchID
      );

      const researchID = userData.researchID;

      const responses = mapServerResponsesToLocal(result, researchID);

      setUserData(prev => ({
        ...prev,
        surveyResponses: responses,
        progress: 4
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

    const handleGenerateQuestionResponses = async questionID => {
      setRegenerateLoading(questionID);
      setError(null);
  
      try {
        const result = await api.generateQuestionResponses(
          userData.userID,
          userData.researchID,
          questionID
        );
  
        const researchID = userData.researchID;
  
        const responses = mapServerResponsesToLocal(result, researchID);

        console.log("Generated responses are:",responses)
  
        setUserData(prev => ({
          ...prev,
          surveyResponses: responses,
          progress: 4
        }));
      } catch (err) {
        setError(err.message);
      } finally {
        setRegenerateLoading(null);
      }
    };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const organizeByQuestion = () => {                                                        // function that returns what is in the brackets
    if (!userData.surveyResponses) return [];                                                       // If there are no results, return an empty array
    
    console.log('Survey Questions:', userData.surveyQuestions);                             // log the survey questions and the results to the console for debugging purposes. 
    console.log('Results:', userData.surveyResponses);
    
    const personaIds = userData.selectedPersonas.map(persona => persona.id);

    console.log('Selected persona ids: ', personaIds);


    return userData.surveyQuestions.map(question => {                                       // iterates over each question in the userData.surveyQuestions array. For each question, it performs the following operations
      
      const questionResponses = userData.surveyResponses.filter(                                    // creates a new array called questionResponses that contain only the filtered results from userData.results
        r => r.questionID === question.questionID && personaIds.includes(r.personaID)       // for every result r it returns true if the result.questionID is the same as as the questionId of the question you're currently iterating through,
      );

      console.log(`Responses for question ${question.questionID}:`, questionResponses);

      return {
        question,
        responses: questionResponses
      };
    });
  };

  const organizeByPersona = () => {                                                                     // function that returns what is in the brackets
    if (!userData.surveyResponses) return [];                                                                   // if there are no userData results, return an empty array
    
    
    console.log("researchID from userData:",userData.researchID);
    console.log("userData.surveyResponses: ",userData.surveyResponses);

    return userData.selectedPersonas.map(persona => {                                                   // iterates over each persona in userData.selectedPersonas. For each variable persona it performs the following operations
      const personaResponses = userData.surveyResponses.filter(                                                 // creates a new array called personaResponses that contain only the filtered results from userData.results
        r => parseInt(r.personaID) === parseInt(persona.id) && r.researchID === userData.researchID     // for every result r in userData.results, it returns true if the personaID of the response matches the id of the persona from selected personas you are currently iterating through. It also checks if the result belongs to the current research. 
      );

      console.log(`Responses for persona ${persona}:`, personaResponses);

      return {                                                                                          // it returns every persona together with all its responses. 
        persona,
        responses: personaResponses
      };
    });
  };
  
  const renderResponsesByQuestion = () => {
    const organizedData = organizeByQuestion();

  // Check if we have no responses at all (progress is 3)
  const hasNoResponses = userData.progress === 3;
  
  if (hasNoResponses) {
    return (
      <Paper sx={{ p: 4, mb: 2, textAlign: 'center', bgcolor: '#f5f5f5' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          No responses have been generated yet
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          Click the "Generate New Responses" button below to generate responses for all questions from your selected personas.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleGenerateResponses}
            disabled={loading}
            size="large"
          >
            {loading ? <CircularProgress size={24} /> : 'Generate New Responses'}
          </Button>
        </Box>
      </Paper>
    );
  }
  
  // Original code for when responses exist
    return (
      <Box>
        {organizedData.map((item, index) => {
          // Check if this question has responses
          const hasResponses = item.responses && item.responses.length > 0;
          
          // If no responses, display a simpler non-accordion view
          if (!hasResponses) {
            return (
              <Paper key={index} sx={{ p: 2, mb: 2, bgcolor: '#f5f5f5' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Question {index + 1}: {item.question.questionText}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  No responses yet for this question.
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={regenerateLoading === item.question.questionID}
                  onClick={() =>
                    handleGenerateQuestionResponses(item.question.questionID)
                  }
                >
                  {regenerateLoading === item.question.questionID
                    ? 'Generating...'
                    : 'Generate Responses for this Question'}
                </Button>
              </Paper>
            );
          }
          
          // For questions with responses, use the accordion view
          return (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  Question {index + 1}: {item.question.questionText}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mb: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={regenerateLoading === item.question.questionID}
                    onClick={() =>
                      handleGenerateQuestionResponses(item.question.questionID)
                    }
                  >
                    {regenerateLoading === item.question.questionID
                      ? 'Regenerating...'
                      : 'Regenerate Responses for this Question'}
                  </Button>
                </Box>
  
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Persona</TableCell>
                        <TableCell>Response</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.responses.map((response, rIndex) => (
                        <TableRow key={rIndex}>
                          <TableCell>
                            {userData.selectedPersonas.find(p => p.id === response.personaID)?.name || response.personaID}
                          </TableCell>
                          <TableCell>
                            {Array.isArray(response.response) ? (
                              response.response.map((ans, i) => (
                                <React.Fragment key={i}>
                                  {ans}
                                  {i < response.response.length - 1 && <br />}
                                </React.Fragment>
                              ))
                            ) : typeof response.response === 'string' ? (
                              response.response.split('\\n').map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < response.response.split('\\n').length - 1 && <br />}
                                </React.Fragment>
                              ))
                            ) : (
                              // Fallback if it's neither a string nor an array
                              <>{JSON.stringify(response.response)}</>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };
  

  const renderResponsesByQuestion2 = () => {
    const organizedData = organizeByQuestion();
    
    return (
      <Box>
        {organizedData.map((item, index) => (

          
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Question {index + 1}: {item.question.questionText}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* NEW Button to regenerate responses for this question */}
              <Box sx={{ mb: 1 }}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={regenerateLoading === item.question.questionID}
                  onClick={() =>
                    handleGenerateQuestionResponses(item.question.questionID)
                  }
                >
                  {regenerateLoading === item.question.questionID
                    ? 'Regenerating...'
                    : 'Regenerate Responses for this Question'}
                </Button>

              </Box>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Persona</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.responses.map((response, rIndex) => (
                      <TableRow key={rIndex}>
                        <TableCell>
                          {userData.selectedPersonas.find(p => p.id === response.personaID)?.name || response.personaID}
                        </TableCell>
                        <TableCell>
                                                    
                          {Array.isArray(response.response) ? (
                            response.response.map((ans, i) => (
                              <React.Fragment key={i}>
                                {ans}
                                {i < response.response.length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : typeof response.response === 'string' ? (
                            response.response.split('\\n').map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < response.response.split('\\n').length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : (
                            // Fallback if it's neither a string nor an array
                            <>{JSON.stringify(response.response)}</>
                          )}

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  const renderResponsesByPersona = () => {
    const organizedData = organizeByPersona();
  // Check if we have no responses at all (progress is 3)
  const hasNoResponses = userData.progress === 3;
  
  if (hasNoResponses) {
    return (
      <Paper sx={{ p: 4, mb: 2, textAlign: 'center', bgcolor: '#f5f5f5' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          No responses have been generated yet
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          Click the "Generate New Responses" button below to generate responses for all questions from your selected personas.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleGenerateResponses}
            disabled={loading}
            size="large"
          >
            {loading ? <CircularProgress size={24} /> : 'Generate New Responses'}
          </Button>
        </Box>
      </Paper>
    );
  }
    return (
      <Box>
        {organizedData.map((item, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Persona {item.persona.id} ({item.persona.age_range}, {item.persona.gender})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.responses.map((response, rIndex) => (
                      <TableRow key={rIndex}>
                        <TableCell>
                          {userData.surveyQuestions.find(q => q.questionID === response.questionID)?.questionText || response.questionID}
                        </TableCell>
                        <TableCell>

                          {Array.isArray(response.response) ? (
                            response.response.map((ans, i) => (
                              <React.Fragment key={i}>
                                {ans}
                                {i < response.response.length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : typeof response.response === 'string' ? (
                            response.response.split('\\n').map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < response.response.split('\\n').length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : (
                            // Fallback if it's neither a string nor an array
                            <>{JSON.stringify(response.response)}</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Step 3: Generate Responses
      </Typography>

      <Paper sx={{ p: 3, mt: 2 }}>
        {!userData.surveyResponses ? (
          <></>
        ) : (
          <Box>
            <Box sx={{ mb: 2 }}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="view mode"
              >
                <ToggleButton value="byQuestion" aria-label="by question">
                  View by Question
                </ToggleButton>
                <ToggleButton value="byPersona" aria-label="by persona">
                  View by Persona
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {viewMode === 'byQuestion' ? renderResponsesByQuestion() : renderResponsesByPersona()}
          </Box>
        )}
      </Paper>
    {/* Only show these buttons if we have responses (progress > 3) */}
    {userData.progress > 3 && (
      <Box>
            <Button
              variant="contained"
              onClick={handleGenerateResponses}
              disabled={loading}
              sx={{ mr: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Generate New Responses'}
            </Button>

            <Button
              variant="outlined"
              onClick={() => handleReportGeneration(userData)}
              disabled={loading}
            >
              Download PDF report
            </Button>

            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                Error: {error}
              </Typography>
            )}
          </Box>
          )}
          
        
      
      <div style={{ display: 'none' }}>
        <ReportContent userData={userData} />
      </div>

      
    </Box>
  );
}

export default Step3Responses;