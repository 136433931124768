// import { researchApi } from './researchApi';
// import { QuestionType } from '../research/ResearchConstants';


// Poll research status until complete or error
  // export const pollResearchStatus = async (userID, researchID) => {
  //   const maxAttempts = 30;
  //   const pollInterval = 8000;
  //   let attempts = 0;
  
  //   while (attempts < maxAttempts) {
  //     const responseBody = await researchApi.getResearch(userID);
  //     const researchData = responseBody.data

  //     // Find the specific research with the given researchID
  //     const research = researchData.find(r => r.researchID === researchID);
  
  //     if (!research) {
  //       throw new Error('Research not found');
  //     }
  
  //     if (research.statusQuestions === 'COMPLETED') {
  //       return research;
  //     } else if (research.statusQuestions === 'ERROR') {
  //       throw new Error('Failed to generate questions');
  //     }
  
  //     await new Promise(resolve => setTimeout(resolve, pollInterval));
  //     attempts++;
  //   }
  
  //   throw new Error('Timeout waiting for research completion');
  // };
  


export function mapServerQuestionsToLocal(questionsObject) {
  /**
   * questionsObject is the response from the server, like:
   * {
   *   q1: { prompt: '...', kind: 'multiple-choice', vocab: ['Yes','No'], ... },
   *   q2: { prompt: '...', kind: 'open', max_length: 200, ... },
   * }
   * So let's turn that into an array of question objects with
   * questionID, questionText, questionType, responseOptions, etc.
   */
  if (questionsObject === null) {
    return null;
  }
  return Object.keys(questionsObject).map((questionID) => {
    const questionData = questionsObject[questionID];
    const isMultipleChoice = questionData.kind === 'multiple-choice';

    let selectMin;
    let selectMax;

    if (isMultipleChoice) {
      // If min/max exist, use them; otherwise default
      selectMin = typeof questionData.min !== 'undefined' ? questionData.min : 1;
      selectMax = typeof questionData.max !== 'undefined' ? questionData.max : questionData.vocab.length;
    }

    return {
      questionID,
      questionText: questionData.prompt,
      questionType: questionData.kind,
      responseOptions: isMultipleChoice ? questionData.vocab : [],
      maxLength: questionData.kind === 'open' ? questionData.max_length : undefined,
      selectMin,
      selectMax,

      // If you also track fixed percentages, you can handle them here
      hasFixedPercentages: questionData.hasFixedPercentages || false,
      responsePercentages: questionData.responsePercentages || [],
    };
  });
}


  // Helper function to format raw API response data into the array structure you need.
  export function mapServerResponsesToLocal(apiData, researchID) {
    // apiData is expected to have the shape:
    // { personaID1: { questionID1: responseText, questionID2: responseText, ... },
    //   personaID2: { questionID1: responseText, questionID2: responseText, ... }, ... }
    if (apiData === null) {
      return null;
    }
    return Object.entries(apiData).flatMap(([personaID, questionMap]) =>
      Object.entries(questionMap).map(([questionID, response]) => ({
        personaID,
        questionID,
        response,
        researchID,
      }))
    );
  }
