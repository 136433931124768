export const blogData = [
    {
      id: 'aiHumanSurvey', 
      title: 'The Integration of AI and Human Expertise in Survey Design',
      image: 'ai-human-survey.jpeg',
      category: 'AI and Human Expertise',
      date: '20 April 2024',
    },
    {
      id: 'aiMarketResearch', 
      title: 'The Rise of AI in Market Research: Revolutionizing Insights',
      image: 'ai-market-research.jpeg',
      category: 'AI in Market Research',
      date: '22 January 2024',

    },
    {
      id: 'syntheticData',
      title: 'Synthetic Data: The Future of Privacy-Conscious Market Research',
      image: 'synthetic-data.jpeg',
      category: 'Privacy and Data Protection',
      date: '13 March 2024',
    },
    {
      id: 'instantflowsAiChallenges',
      title: 'InstantFlows: Tackling AI Challenges with Trusted Insights and Solutions',
      image: 'instantflows-ai-challenges.jpeg',
      category: 'AI Solutions',
      date: '13 January 2024',
      authors: ['George Gittins', 'Priscille Niakale'] // Optional
    },
    {
      id: 'realTimeInsights',
      title: 'Real-Time Insights: The Power of AI-Simulated Responses',
      image: 'real-time-insights.jpeg',
      category: 'Real-Time Insights',
      date: '02 February 2024',
    },
  ];
  